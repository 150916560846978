body {
  background: linear-gradient(105.83deg, #476686 4.74%, rgba(17, 27, 37, 0.1) 104.88%);
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.header {
  font-size: 5rem;
  font-weight: normal;
  color: #fff;
  text-shadow: #476686 0px 0px 10px;
}